<template>
    <div class="emergencyEatXfbm">
        <edit-btns class="btn-box" 
            :bnts="bnts"
            @saveClick="saveClick()"  
            @deleteClick="deleteClick()"
            @goBackClick="goBack()"/>
        <div class="content-box">
            <div class="left-box">
                <div class="table-box grey-table" v-table-el-height="'tableEl'">
                    <el-table class="el-table--scrollable-y draggable-table" ref="tableEl" border :data="tableList"  
                        row-key="Eat_XFBMID"
                        style="width: 100%;height:100%"  
                        highlight-current-row>
                        <el-table-column prop="Eat_XFName" label="菜品名称" min-width="100" align="left">
                            <template #default="scope">
                                <div class="index">{{scope.$index+1}}</div>
                                {{scope.row.Eat_XFName}}
                            </template>
                        </el-table-column> 
                        <el-table-column width="110">
                            <template #header>
                                <el-dropdown trigger="click" :max-height="100">
                                    <span class="el-dropdown-link" >
                                    是否日清<span class="batch" >(批量)</span>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="batch('Eat_Clean',true)">是</el-dropdown-item>
                                            <el-dropdown-item @click="batch('Eat_Clean',false)">否</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </template>
                            <template #default="scope">
                                <el-checkbox v-model="scope.row.Eat_Clean" />
                            </template>
                        </el-table-column>
                        
                        <el-table-column class-name="edit" prop="" label="操作" width="60">
                            <template #default="scope">
                                <el-button type="text" size="small" @click="cancelData(scope.$index)">取消</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="排序" width="90">
                            <template #default>
                                <el-button type="text" size="small"><i class="iconfont icon-tuozhuai"></i>按住拖拉</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="right-box">
                <div class="category-nav food-main-box"  v-if="orderMap_Type==2 ||orderMap_Type==1" :style="foodMainStyle">
                    <categorys-page 
                        :data="foodMainCategory" 
                        :row="foodMainArg.row" 
                        :col="foodMainArg.col" 
                        :selectIndex="mainCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodMainPageIndex"
                        navFirstName="全部大类"
                        v-slot="{item}" @navClick="onFoodNav">
                        {{item.MainCategory_Name}}
                    </categorys-page>
                </div>
                <div class="category-nav food-sub-box"  v-if="orderMap_Type==3 ||orderMap_Type==1" :style="foodSubStyle">
                    <categorys-page  
                        :data="foodSubCategorys" 
                        :row="foodSubArg.row" 
                        :col="foodSubArg.col" 
                        :selectIndex="subCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodSubPageIndex"
                        navFirstName="全部小类"
                        v-slot="{item}" @navClick="onFoodSubNav">
                        {{item.SubCategory_Name}}
                    </categorys-page>
                </div>

                <div class="menu" :style="[menuStyle,{'--selectedColor':sysSetting?.EverydayEmergencyDishFoodBackgroundColor}]">
                    <!-- 菜单 -->
                    <layout-rc class="item-box clearfix" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :isVarCss="true">
                        <div class="item-li" :class="{selected:tableList?.some(it=>it.Eat_XFBMID==item.Food_ID)}" rc-item v-for="item in foodsPages" :key="item"  @click="addItem(item)">
                            <div class="title">
                                {{item.Food_Name}}
                            </div>
                            <div class="bottom-box">
                                <span class="text-left" v-if="item.Is_Limited && item.Is_LimitedProject">剩: {{item.RemainNum}}</span>
                                <span class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</span>
                            </div>
                            <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                            <div class="tag tag-grey" v-else-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                            <div class="tag tag-limited" v-else-if="item.Is_Limited">限量</div>
                        </div>
                    </layout-rc>
                </div>
                <div class="search-box">
                    <key-board-input class="search"
                        ref="searchBox"
                        type="text" v-model="varietySelect" placeholder="菜品模糊检索"
                        isKeyEnterClose isBlurClose
                        :focusLock="true"
                        :keyOptions="{isKeyDownEnter:true}"
                        @keydown.enter="onEnter()"
                    ></key-board-input>
                    <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                    <div class="float-right">
                        <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                        <span class="page-number">{{ pageIndex }}/{{ pageCount }}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from "sortablejs";
/**急推菜品设置 */
export default {
    name:"emergencyEatXfbm",
    computed:{
        /**编辑按钮 */
        bnts(){
            let data=["save",{key:"delete",text:"全部删除"},"goBack"];
            return data;
        },
        //系统参数配置
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
        /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
        orderMap_Type(){
            return this.sysSetting.OrderMap_Type||1;
        },
        //所以菜品数据
        getPosFoodData(){
            return this.posFoodData?.data||[];
        },
        //大类
        foodMainCategory(){
            return this.getPosFoodData;
        },
        //全部小类
        allSubCategorys(){
            let all=[];
            this.foodMainCategory?.forEach(it=>{
                all=all.concat(it.FoodSubCategorys);
            });
            return all;
        },
        //小类
        foodSubCategorys(){
            let foodSubCategorys=this.allSubCategorys;
            if(this.mainCategoryIndex>0){//大类分类 第几
                foodSubCategorys=this.foodMainCategory[this.mainCategoryIndex-1].FoodSubCategorys;
            }
            return foodSubCategorys;
        },
        /**全部菜品 过滤推荐菜分类*/
        allFoods(){
            let list=[];
            this.allSubCategorys?.forEach(it=>{
                list=list.concat(it.PosFoods);
            })
            return list;
        },
        /**菜品分页显示 */
        foodsPages(){
            return this.posFoodsFilter?.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        //一页多少条
        pageSize(){
            let size=this.arg.row*this.arg.col;
            return size;
        },
        //总条数
        pageCount(){
            return Math.ceil(this.posFoodsFilter.length/this.pageSize);
        },
        //分类 下菜品
        posFoods(){
            let list=[];
            if(this.subCategoryIndex>0){//分类 下菜品
                list=this.foodSubCategorys[this.subCategoryIndex-1].PosFoods;
            }else{//全部分类 下菜品  
                this.foodSubCategorys?.forEach(it=>{
                    list=list.concat(it.PosFoods);
                })
            }
            return list;
        },
        //筛选 当前 菜品数据
        posFoodsFilter(){
            let list=this.posFoods;
            let menuTxt=this.varietySelect?.toLowerCase();
            if(menuTxt){
                list=list.filter(it=>(it.Food_Name||"").toLowerCase().indexOf(menuTxt)>=0 ||
                        (it.Food_PinYin||"").toLowerCase().indexOf(menuTxt)>=0 || 
                        (it.Food_Code||"").toLowerCase().indexOf(menuTxt)>=0
                );
            }
            return list;
        },
    },
    watch:{
        "sysSetting.DisplayingConfigs":{//桌台和点单界面显示设置
            handler(newVal){
                newVal?.forEach((it)=>{
                    let style={};
                    if(it.ScreenPx>0){
                        style["height"]=it.ScreenPx+"%";
                    }
                    if(it.Button_Color){
                        style["--bgColor"]=it.Button_Color;
                    }
                    if(it.Font_Name){
                        style["--fontFamily"]=it.Font_Name;
                    }
                    if(it.Font_Size){
                        style["--fontSize"]=it.Font_Size+"px"
                    }
                    if(it.Font_Color){
                        style["--fontColor"]=it.Font_Color
                    }
                    let arg={row:it.IntRow,col:it.IntCol};
                    if(it.Config_Kind==11){//菜品大类显示类型
                        this.foodMainArg=arg;
                        this.foodMainStyle=style;
                    }else if(it.Config_Kind==12){//为菜品小类显示类型   
                        this.foodSubArg=arg;
                        this.foodSubStyle=style;
                    }else if(it.Config_Kind==13){//为菜品显示类型   
                        this.arg={row:it.IntRow,col:it.IntCol,marginW:'6',marginH:'6'};
                        this.menuStyle=style;
                    }else if(it.Config_Kind==14){//为功能键显示类型   
                        this.footerArg=arg;
                        this.footerStyle=style;
                    }
                })
            },
            immediate:true
        },
        /**菜品搜索 */
        varietySelect(){
            let menuTxt=this.varietySelect.toLowerCase();
            if(menuTxt){
                this.mainCategoryIndex=0;
                this.subCategoryIndex=0;
            }
            
        },
        pageCount(){
            if(this.pageCount<this.pageIndex){
                this.pageIndex=1;
            }
        }
    },
    data(){
        let arg={row:6,col:6,marginW:'6',marginH:'6'}
        let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            arg={row:globalSysSetting.OrderMap_X,col:globalSysSetting.OrderMap_Y,marginW:'6',marginH:'6'};
        }
        return {
            menuStyle:{},
            //大类 样式
            foodMainStyle:{},
            foodMainArg:{row:2,col:8},
            //大类分页 当前页码 
            foodMainPageIndex:1,
            mainCategoryIndex:0,
            //小类 样式
            foodSubStyle:{},
            foodSubArg:{row:2,col:8},
            //小类分页 当前页码 
            foodSubPageIndex:1,
            subCategoryIndex:0,
            arg:arg,
            /**菜品检索 */
            varietySelect:'',
            //菜品数据 
            posFoodData:null,
            /**菜品页码 */
            pageIndex:1,
            tableList:[],
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.$cacheData.DeskPosconfigure().then().catch(e=>{
            console.log("基本配置获取失败："+e.message);
        })
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        
        //拖拉排序
        const tbody = document.querySelector(".draggable-table .el-table__body-wrapper tbody");
        new Sortable(tbody, {
            animation: 150,
            sort: true,
            scroll:true,
            // 需要在odEnd方法中处理原始eltable数据，使原始数据与显示数据保持顺序一致
            onEnd: ({ newIndex, oldIndex }) => {
                console.log(newIndex, oldIndex)
                const targetRow = this.tableList[oldIndex];
                this.tableList.splice(oldIndex, 1);
                console.log(this.tableList)
                this.tableList.splice(newIndex, 0, targetRow);
                console.log(this.tableList)
            },
        });

        this.loadData();
    },
    methods:{
        //返回 或 返回坐台
        goBack(){
            this.$router.push('setup');
        },
        setBottomH(){
            let top=this.$refs.searchBox?.getBoundingClientRect().top;
            if(top>0){
                return window.innerHeight-top;
            }
            return 0;
        },
        /**点击大类*/
        onFoodNav(item,index){
            if(item){
                this.mainCategoryIndex=index;
            }else{//所有
                this.mainCategoryIndex=0;
            }
            this.foodSubPageIndex=0;
            this.$nextTick(()=>{
                this.foodSubPageIndex=1;
            })
            this.onFoodSubNav(null,0)
        },
        /**点击小类*/
        onFoodSubNav(item,index){
            this.subCategoryIndex=index;
            this.varietySelect="";
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. pageIndex<this.pageCount){
                    this.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.pageIndex<=1?1:this.pageIndex--;
            }
        },
        /**批量操作 */
        batch(key,newVal){
            this.tableList.forEach(it=>{
                it[key]=newVal;
            })
        },
        addItem(item){
            if(!this.tableList){
                this.tableList=[];
            }
            if(!this.tableList.some(it=>it.Eat_XFBMID==item.Food_ID)){
                this.tableList.push({
                    Eat_XFBMID:item.Food_ID,
                    Eat_XFCode:item.Food_Code,
                    Eat_XFName:item.Food_Name,
                    Eat_Clean:true,
                })
            }
        },
        /**移除 数据 */
        cancelData(index){
            this.tableList.splice(index,1)
        },
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetEverydayEmergencyDish", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.tableList=d.ResponseBody||[];
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**保存 */
        saveClick(){
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.tableList?.forEach((it,index)=>{
                it.Eat_OrderNum=index;
            })
            let param={
                Operator_Name:userInfo?.Login_Name,
                EmergencyDishlist:this.tableList
            }
            console.log("SetEverydayEmergencyDish",param)
            this.$httpAES.post("Bestech.CloudPos.SetEverydayEmergencyDish",param).then((d)=>{
                console.log("SetEverydayEmergencyDish",d)
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败：",e);
            })
        },
        /**删除 */
        deleteClick(){
            const loading = this.$loading({
                text: "数据删除中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                Operator_Name:userInfo?.Login_Name,
                EmergencyDishlist:[]
            }
            this.$httpAES.post("Bestech.CloudPos.SetEverydayEmergencyDish",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=[];
                    this.$message.success("数据删除成功!");
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据删除失败："+e);
                console.log("数据删除失败：",e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './emergencyEatXfbm.scss'
</style>